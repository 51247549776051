import React, { useState, useEffect } from 'react';
import { Button } from '../../../components/button';

export default function useCaptchaButton() {
  const [loadCaptcha, setLoadCaptcha] = useState<boolean>(false);
  const [CaptchaButton, setCaptchaButton] = useState<React.LazyExoticComponent<any>>();

  useEffect(() => {
    if (loadCaptcha) {
      const captcha = React.lazy(() => import('@feature/contact/components/contact-form-submit'));
      setCaptchaButton(captcha);
    }
  }, [loadCaptcha]);

  return {
    loadCaptcha,
    setLoadCaptcha,
    CaptchaButton: CaptchaButton ? CaptchaButton : Button,
  };
}
import { PropsWithChildren } from "react";
import styles from "./modal-content.module.scss";
import classNames from "classnames";

type ModalContentProps = {
  className?: string;
};

export default function ModalContent({ children, className }: PropsWithChildren<ModalContentProps>) {
  return <div className={classNames(styles.container, className)}>{children}</div>;
}

import { ReactComponent as EnvelopeIcon } from "@assets/envelope.svg";
import styles from "./contact-modal.module.scss";
import { FunctionComponent } from "react";
import Paragraph from "@feature/typography/paragraph";
import ModalContent from "@feature/modal/components/modal-content";
import ModalHeader from "@feature/modal/components/modal-header";
import { Modal } from "@feature/modal/modal";

export const ContactModal: FunctionComponent<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} size="md">
      <ModalHeader title="Dziękujemy za wiadomość" />
      <ModalContent className={styles.modal}>
        <EnvelopeIcon className={styles.icon} />
        <Paragraph size={9}>
          Otrzymaliśmy twoją wiadomość i niezwłocznie się z tobą skontaktujemy.
        </Paragraph>
      </ModalContent>
    </Modal>
  );
};

import styles from "./footer.module.scss";
import {Link, useShared} from "@interactivevision/visitor-react";
import {SectionWrapper} from "../wrappers";
import {ReactComponent as Logo} from "../../assets/company-logo_white.svg";
import {ReactComponent as PhoneIcon} from "../../assets/phone.svg";
import {ReactComponent as Pin} from "../../assets/pin.svg";
import {ReactComponent as Envelope} from "../../assets/envelope.svg";
import {ReactComponent as Whatsup} from "../../assets/footer-whatsup.svg";
import {ReactComponent as Messanger} from "../../assets/footer-messanger.svg";
import {ReactComponent as Instagram} from "../../assets/footer-instagram.svg";
import {ReactComponent as Facebook} from "../../assets/footer-facebook.svg";
import {ReactComponent as IvLogo} from "../../assets/iv-logo.svg";
import {route} from "@interactivevision/routing";
import React, {useState} from "react";
import classNames from "classnames";
import {SeeMoreButton} from "../../components/see-more";

export default function Footer() {
    const shared = useShared();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            <footer className={styles.footer}>
                <SectionWrapper className={styles.logo}>
                    <Link href={route("homepage_module")} title="Centrum Bruku">
                        <Logo/>
                    </Link>
                </SectionWrapper>
                <SectionWrapper as="div" className={styles.upper}>
                    <div className={classNames(styles.header, styles.contactHeader)}>
                        <div className={styles.title}>Kontakt</div>
                        <SeeMoreButton
                            className={classNames(styles.toggle, {[styles.open]: isOpen})}
                            onClick={() => setIsOpen(!isOpen)}
                        />
                    </div>
                    <div className={classNames(styles.contact, {[styles.open]: isOpen})}>
                        <div className={styles.box}>
                            <div className={styles.title}>Dane kontaktowe</div>

                            <a href={`tel:${shared?.contact.phone_2}`} className={styles.item}>
                                <PhoneIcon/>
                                {shared?.contact.phone_2}
                            </a>
                            <a href={`tel:${shared?.contact.phone}`} className={styles.item}>
                                <PhoneIcon/>
                                {shared?.contact.phone}
                            </a>

                            <a href={`mailto:${shared?.contact.email}`} className={styles.item}>
                                <Envelope/>
                                {shared?.contact.email}
                            </a>
                        </div>

                        <div className={styles.box}>
                            <div className={styles.title}>Lokalizacja</div>
                            <div className={styles.item}>
                                <Pin/>
                                <span>39-200 Dębica, ul. Lwowska 26</span>
                            </div>
                        </div>
                        <div className={classNames(styles.box, styles.socials)}>
                            {shared?.contact.whatsup && (
                                <a href={shared?.contact.whatsup} title="Whatsup">
                                    <Whatsup/>
                                </a>
                            )}
                            {shared?.contact.messanger && (
                                <a href={shared?.contact.messanger} title="Messanger">
                                    <Messanger id="test"/>
                                </a>
                            )}
                            {shared?.contact.instagram && (
                                <a href={shared?.contact.instagram} title="Instagram">
                                    <Instagram/>
                                </a>
                            )}
                            {shared?.contact.facebook && (
                                <a href={shared?.contact.facebook} title="Facebook">
                                    <Facebook/>
                                </a>
                            )}
                        </div>
                    </div>

                    <div className={classNames(styles.openHours, {[styles.open]: isOpen})}>
                        <div className={styles.title}>Godziny otwarcia sklepu</div>
                        <div className={styles.item}>
                            <span className={styles.accent}>Pon.-Pt.: </span> 7:00 - 17:00 <br/>
                        </div>
                        <div className={styles.item}>
                            <span className={styles.accent}>Sob. </span> 7:00 - 13:00
                        </div>
                    </div>

                    <div className={styles.menu}>
                        {shared?.menu.menu_bottom &&
                            shared?.menu.menu_bottom.map((element, index) => (
                                <MenuGroup group={element} key={index} open={index === 0}/>
                            ))}
                    </div>
                </SectionWrapper>
                <section className={styles.lower}>
                    <SectionWrapper className={styles.wrapper}>
                        <nav className={styles.links}>
                            <Link href="/polityka-prywatnosci">Polityka prywatnosci</Link>
                        </nav>

                        {/*<div className={styles.realisation}>
                            <span>Realizacja:</span>
                            <Link href={"https://interactivevision.pl"}><IvLogo/></Link>
                        </div>*/}
                    </SectionWrapper>
                </section>
            </footer>
        </>
    );
}

type MenuGroup = {
    open?: boolean;
    group: {
        name: string;
        url?: string;
        children: Array<{
            url: string;
            name: string;
        }>;
    };
};

function MenuGroup({group, open = false}: MenuGroup) {
    const [isOpen, setIsOpen] = useState<boolean>(open);

    return (
        <div className={classNames(styles.group, {[styles.open]: isOpen})}>
            <div className={styles.header}>
                {group.url ? (
                    <Link href={group.url} className={styles.title}>
                        {group.name}
                    </Link>
                ) : (
                    <div className={styles.title}>{group.name}</div>
                )}
                {group.children.length > 0 && (
                    <SeeMoreButton className={styles.toggle} onClick={() => setIsOpen(!isOpen)}/>
                )}
            </div>
            <ul className={styles.children}>
                {group.children.map((element, index) => (
                    <li key={index}>
                        <Link href={element.url}>{element.name}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

import styles from "./partners.module.scss";
import {Swiper, SwiperSlide} from "swiper/react";
import {Partner} from "@models/partner";
import {Autoplay, SwiperOptions} from "swiper";
import {SectionWrapper} from "../../../layouts/wrappers";
import SectionTitle from "../../typography/section-title";
import {Picture} from "@interactivevision/picture";
import {Link} from "@interactivevision/visitor-react";

type PartnersSection = {
    partners: Partner[];
};

export default function Partners({partners}: PartnersSection) {
    const options: SwiperOptions = {
        modules: [Autoplay],
        init: partners.length > 1,
        speed: 10000,
        slidesPerView: "auto",
        autoplay: {
            delay: 0,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            1: {
                spaceBetween: 24,
            },
            601: {
                spaceBetween: 36,
            },
            1280: {
                spaceBetween: 48,
            },
            1441: {
                spaceBetween: 64,
            },
        },
    };

    if (partners.length === 0) {
        return null;
    }

    const makeLogos = () => {
        const logos: Partner[] = [];
        let i = 0;

        while (logos.length < 24) {
            logos.push(partners[i]);
            i++;

            if (i >= partners.length) {
                i = 0;
            }
        }

        return logos;
    };

    return (
        <section className={styles.section}>
            <SectionWrapper
                as={SectionTitle}
                dangerouslySetInnerHTML={{__html: "Nasi <span>Partnerzy</span>"}}
                className={styles.title}
                data-aos="fade-up"
            />
            <Swiper {...options} className={styles.swiper}>
                {makeLogos().map((element, index) => (
                    <SwiperSlide key={index} className={styles.swiperSlide}>
                        {element.photos[0] && (
                            <div className={styles.slide}>
                                {element.url ? <Link href={element.url}>
                                        <Picture
                                            config="partner"
                                            sizes={[{size: "324x60"}]}
                                            photoable={element}
                                            type="logo"
                                        />
                                    </Link> :
                                    <Picture
                                        config="partner"
                                        sizes={[{size: "324x60"}]}
                                        photoable={element}
                                        type="logo"
                                    />}
                            </div>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
}

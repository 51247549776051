import React, { forwardRef, HTMLAttributes, PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "./wrappers.module.scss";

export type BaseType = HTMLAttributes<any> & {
  as?: React.ElementType;
}

export const SectionWrapperBig = forwardRef<any, PropsWithChildren<BaseType>>(({ as, className, ...props }, ref) => {
  const Component = as || 'section';

  return (
    <Component {...props} className={classNames(styles.sectionBig, className)} ref={ref}>
      {props.children}
    </Component>
  )
});

export const SectionWrapper = forwardRef<any, PropsWithChildren<BaseType>>(({ as, className, ...props }, ref) => {
  const Component = as || 'section';

  return (
    <Component {...props} className={classNames(styles.section, className)} ref={ref}>
      {props.children}
    </Component>
  )
});

export const ContentWrapper = forwardRef<any, PropsWithChildren<BaseType>>(({ as, className, ...props }, ref) => {
  const Component = as || 'section';

  return (
    <Component {...props} className={classNames(styles.content, className)} ref={ref}>
      {props.children}
    </Component>
  )
});

export const BlogWrapper = forwardRef<any, PropsWithChildren<BaseType>>(({ as, className, ...props }, ref) => {
  const Component = as || 'section';

  return (
    <Component {...props} className={classNames(styles.blog, className)} ref={ref}>
      {props.children}
    </Component>
  )
});

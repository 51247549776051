import React, { FunctionComponent, useRef, PropsWithChildren } from 'react';
import { useTransitionHeight } from '../hooks/use-transition-height';
import classNames from 'classnames';
import styles from './collapse.module.scss';
import { ReactComponent as Arrow } from '../assets/chevron-down.svg';

export type CollapseType = {
  open?: boolean;
  title: React.ReactNode;
  className?: string;
  accent?: boolean;
}

export const Collapse: FunctionComponent<PropsWithChildren<CollapseType>> = ({ children, open = false, title, className, accent, ...props }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { show, setShow } = useTransitionHeight(contentRef, open);

  return (
    <div className={classNames(styles.collapse, className, { [styles.open]: show })} {...props}>
      <header className={styles.header} onClick={() => setShow(!show)}>
        <div className={classNames(styles.title, { [styles.accent]: accent })}>{title}</div>
        <button className={styles.toggle} aria-label="Toggle">
          <Arrow/>
        </button>
      </header>
      <div className={styles.content} ref={contentRef}>
        <div className={styles.hidden}>
          {children}
        </div>
      </div>
    </div>
  );
};
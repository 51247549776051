import classNames from "classnames";
import styles from "./modal-header.module.scss";
import { PropsWithChildren } from "react";

type Props = {
  title?: string;
  className?: string;
};

export default function ModalHeader({ title, children, className }: PropsWithChildren<Props>) {
  return (
    <header className={classNames(styles.header, className)}>
      {title && <div className={styles.title}>{title}</div>}
      {children && <p>{children}</p>}
    </header>
  );
}

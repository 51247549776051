import styles from "./contact-form.module.scss";
import { SectionWrapper } from "../../../layouts/wrappers";
import SectionTitle from "../../typography/section-title";
import ImgPng from "@public/static/contact-form/contact-person.png";
import ImgWebp from "@public/static/contact-form/contact-person.webp";
import Paragraph from "../../typography/paragraph";
import { Form } from "react-final-form";
import { Grid, GridRow } from "../../../components/grid";
import { useSubmitForm } from "../../form/hooks/use-submit-form";
import { FieldInput } from "../../form/fields/field-input";
import { FieldTextarea } from "../../form/fields/field-textarea";
import { FieldCheckbox } from "../../form/fields/field-checkbox";
import { ContactModal } from "../modals/contact-modal";
import { ReactComponent as Sygnet } from "@assets/sygnet.svg";
import useCaptchaButton from "@feature/contact/hooks/use-captcha-button";
import { EMPTY_IMAGE } from "@feature/shared/helpers/empty-image";
import { useShared } from "@interactivevision/visitor-react";

export default function ContactForm() {
  const shared = useShared();

  return (
    <section className={styles.section} id="kontakt">
      <Sygnet className={styles.detail} />
      <SectionWrapper as={SectionTitle} className={styles.sectionTitle} data-aos="fade-up">
        Skontaktuj się <span>z nami</span>
      </SectionWrapper>

      <SectionWrapper as="div" className={styles.container}>
        <div className={styles.column}>
          <h2 className={styles.title} data-aos="fade-up">
            Potrzebujesz wyceny?
          </h2>
          <Paragraph size={9} weight={600} data-aos="fade-up" data-aos-delay="100">
            Nasz doradca chętnie, pomoże i doradzi. Uzupełnij formularz i wyślij zapytanie
          </Paragraph>
          <div className={styles.contact} data-aos="fade-up" data-aos-delay="150">
            <span>lub zadźwoń</span>
            <br />
            <a href={`tel:${shared?.contact.phone_2}`}>{shared?.contact.phone_2}</a>
            <br />
            <a href={`tel:${shared?.contact.phone}`}>{shared?.contact.phone}</a>
          </div>
          <div className={styles.img}>
            <picture>
              <source srcSet={ImgPng} type="image/png" media="(min-width: 870px)" />
              <source srcSet={ImgWebp} type="image/webp" media="(min-width: 870px)" />
              <source srcSet={EMPTY_IMAGE} type="image/png" media="(max-width: 869px)" />
              <img src={ImgPng} alt="ContactForm" loading="lazy" />
            </picture>
          </div>
        </div>

        <FormWrapper />
      </SectionWrapper>
    </section>
  );
}

export function FormWrapper() {
  const { onSubmit, showModal, setShowModal, submitting } = useSubmitForm();
  const { loadCaptcha, CaptchaButton, setLoadCaptcha } = useCaptchaButton();
  const shared = useShared();

  return (
    <>
      <ContactModal isOpen={showModal} onClose={() => setShowModal(false)} />

      <Form
        onSubmit={onSubmit}
        initialValues={{
          rodo: false,
          token: "",
          page: (window && window.location.pathname) || "",
        }}>
        {({ handleSubmit }) => (
          <div className={styles.column} onFocus={() => setLoadCaptcha(true)} tabIndex={0}>
            <form onSubmit={handleSubmit} noValidate className={styles.form}>
              <FieldInput type="hidden" name="token" />
              <GridRow>
                <FieldInput label="Imię" name="name" />
              </GridRow>
              <GridRow>
                <Grid className={styles.grid}>
                  <FieldInput label="Email" name="email" type="email" required />
                  <FieldInput label="Telefon" name="phone" />
                </Grid>
              </GridRow>
              <GridRow>
                <FieldTextarea name="text" label="Wiadomość" required />
              </GridRow>
              <GridRow>
                <FieldCheckbox name="rodo" required>
                  Wyrażam zgodę na przetwarzanie moich danych osobowych przez Gawbud Łukasz Gawlik z
                  siedzibą w 39-200 Dębica ul. Krucza 7 (dalej: „CentrumBruku”) zawartych w
                  formularzu kontaktowym w celu realizacji przesłanego za jego pośrednictwem
                  zgłoszenia. Zapoznałem/zapoznałam się z klauzulą informacyjną. Jestem
                  świadom/świadoma, iż moja zgoda może być odwołana w każdym czasie poprzez wysłanie
                  maila na adres:
                  <a href={`mailto:${shared?.contact.email}`}>{shared?.contact.email}</a>, co
                  skutkować będzie usunięciem moich danych osobowych przez CentrumBruku.
                </FieldCheckbox>
              </GridRow>
              <GridRow>
                <CaptchaButton disabled={!loadCaptcha || submitting}>Wyślij</CaptchaButton>
              </GridRow>
            </form>
          </div>
        )}
      </Form>
    </>
  );
}

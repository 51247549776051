import { RefObject, useEffect, useState } from "react";

export function useTransitionHeight<T extends HTMLElement>(ref: RefObject<T>, isOpen: boolean = false) {
  const [open, setOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.height = ref.current.scrollHeight + 'px';
        }
      }, 1)
    } else {
      if (ref.current) {
        ref.current.style.height = 0 + 'px';
      }
    }
  }, [open, ref]);

  const setShow = (value: boolean) => setOpen(value);

  return {
    show: open,
    setShow,
  }
}

import { ReactComponent as Close } from "@assets/close.svg";
import classNames from "classnames";
import { PropsWithChildren, useEffect, useRef } from "react";
import styles from "./modal.module.scss";

export type BaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  size?: "sm" | "md" | "lg" | "xl";
  onFocus?: () => void;
};

export function Modal({
  isOpen,
  onClose,
  className,
  size = "lg",
  children,
  onFocus,
}: PropsWithChildren<BaseModalProps>) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
      document.body.classList.add("lock");
    } else {
      document.body.classList.remove("lock");
    }

    return () => {
      const ref = dialogRef.current;

      ref && ref.close();
      document.body.classList.remove("lock");
    };
  }, [isOpen, dialogRef]);

  if (!isOpen) {
    return null;
  }

  return (
    <dialog
      className={classNames(styles.dialog, className, styles[size])}
      ref={dialogRef}
      onFocus={onFocus}>
      <button className={styles.close} onClick={onClose} aria-label="Zamknij">
        <Close />
      </button>
      {children}
    </dialog>
  );
}

import { Base, FontSize, Weight } from './base';
import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';
import styles from './section-title.module.scss';
import classNames from 'classnames';

export type SectionTitleProps = HTMLAttributes<HTMLHeadingElement> & {
  weight?: Weight;
  size?: FontSize;
  as?: 'h1' | 'h2' | 'h3' | 'h4';
}

const SectionTitle = forwardRef<any, PropsWithChildren<SectionTitleProps>>(({className, ...props}, ref) => {
  return (
    <Base as={props.as || 'h2'}
      size={props.size || 4}
      weight={props.weight || 700}
      ref={ref}
      className={classNames(className, styles.title)}
      {...props}
    >
      {props.children}
    </Base>
  );
});

export default SectionTitle;

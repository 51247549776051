import { FunctionComponent, useState } from "react";
import styles from "./call-to-action.module.scss";
import { ReactComponent as Icon } from "../assets/envelope.svg";
import QuickContactModal from "@feature/contact/modals/quick-contact-modal";
import { useSubmitForm } from "@feature/form/hooks/use-submit-form";
import { ContactModal } from "@feature/contact/modals/contact-modal";

export type CallToActionProps = {};

export const CallToAction: FunctionComponent<CallToActionProps> = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState([]);
  const { onSubmit, showModal, setShowModal, submitting } = useSubmitForm();

  const handleSubmit = async (values: Object, api: any) => {
    return await onSubmit(values, api)
        .then((response) => {
            if(response) {
                setErrors(response);
            } else {
                setOpen(false)
            }

            return response
        });
  };

  return (
    <>
      <ContactModal isOpen={showModal} onClose={() => setShowModal(false)} />
      <QuickContactModal
        isOpen={open}
        onClose={() => setOpen(false)}
        onSubmit={handleSubmit}
        submitting={submitting}
        errors={errors}
      />
      <button
        className={styles.container}
        title="Skontaktuj się z nami"
        onClick={() => setOpen(true)}>
        <span className={styles.icon}>
          <Icon />
        </span>
        <span className={styles.title}>Zapytaj o wycene</span>
      </button>
    </>
  );
};

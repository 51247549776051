import { useState } from "react";
import { submitForm } from "../../../helpers/submit-form";

export function useSubmitForm() {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const onSubmit = async (values: Object, api: any) => {
    setSubmitting(true);

    try {
      await submitForm(values).then(() => {
        setShowModal(true);
        setSubmitting(false);
        api.reset();
      });
    } catch (response: any) {
      setSubmitting(false);
      return response.errors;
    }
  }

  return {
    onSubmit,
    showModal,
    setShowModal,
    submitting,
  }
}

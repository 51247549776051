import {FunctionComponent, useState} from "react";
import {Link} from "@interactivevision/visitor-react";
import styles from "./navbar-item.module.scss";
import {ReactComponent as ChevronDown} from "../../../assets/chevron-down.svg";
import classNames from "classnames";

export type NavbarItemProps = {
    className?: string;
    url: string;
    name: string;
    children: NavbarItemProps[];
};

export const NavbarItem: FunctionComponent<{ item: NavbarItemProps }> = ({item}) => {
    const [showSubmenu, setShowSubmenu] = useState<boolean>(false);

    return (
        <li className={classNames(styles.item, item.className, {[styles.withSubmenu]: item.children.length > 0})}>
            <div className={styles.link}>
                <Link href={item.url}>{item.name}</Link>
                {item.children.length > 0 && (
                    <button
                        className={styles.toggle}
                        onClick={() => setShowSubmenu(!showSubmenu)}
                        title="Toggle">
                        <ChevronDown/>
                    </button>
                )}
            </div>

            {item.children.length > 0 && (
                <ul className={classNames(styles.submenu, {[styles.show]: showSubmenu})}>
                    {item.children.map((element, index) => (
                        <li key={index}>
                            <Link href={element.url} className={styles.link}>
                                {element.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};

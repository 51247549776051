import React, { FunctionComponent, HTMLAttributes } from 'react';
import styles from './see-more.module.scss';
import { ReactComponent as ChevronRight } from '../assets/chevron-right.svg';
import classNames from 'classnames';

export type SeeMoreProps = {
  className?: string;
};

export const SeeMore: FunctionComponent<SeeMoreProps> = ({ className }) => {
  return (
    <div className={classNames(styles.more, className)}>
      <SeeMoreButton/>
    </div>
  );
};

type SeeMoreButtonProps = HTMLAttributes<HTMLDivElement>;

export const SeeMoreButton: FunctionComponent<SeeMoreButtonProps> = ({ className, ...props }) => {
  return (
    <div className={classNames(styles.button, className)} {...props}>
      <ChevronRight/>
    </div>
  )
}
